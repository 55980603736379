<template>
  <div>
    <div v-if="currentType === 'customer'" class="mt-2">
      <b-tabs
        v-model="activeTab"
        pills
        content-class="mt-3"
        nav-class="mb-0"
        active-nav-item-class="bg-info box-shadow-info border-info info"
        class="tab-customer-ticket-module1"
        @input="handleTabClick"
      >
        <div
          v-for="(ticket, index) in G_COUNT_PENDING_CUSTOMER_TICKETS_BY_MODULE"
          :key="index"
        >
          <b-tab :title-link-class="[bgTabsNavs, 'sub-tab px-3 h-full']">
            <template #title>
              <div
                v-b-tooltip.hover.bottom="
                  ticket.alias === '1' ? 'CEO' : ticket.alias
                "
                v-b-tooltip.hover.v-primary
                style="padding: 5px 30px"
              >
                <template v-if="ticket.icons">
                  <img
                    :src="`/assets/${ticket.icons}`"
                    alt="Logo"
                    height="30"
                    width="30"
                  />
                </template>
                <template v-else>
                  {{ ticket.module_name }}
                </template>
              </div>

              <span
                v-if="ticket.counter_pending > 0"
                style="padding-right: 20px !important"
              >
                <feather-icon
                  icon
                  :badge="
                    ticket.counter_pending > 99 ? '99+' : ticket.counter_pending
                  "
                  badge-classes="badge-danger badge-glow counter-tabs-customer-tickets-modules"
                />
              </span>
            </template>
            <grid-customer-tickets
              v-if="activeTab === index"
              ref="routeView"
              :key="gridIndex"
              :module="ticket.module_id"
              :code-search="searchCode"
              style="margin-top: -3rem"
              @refreshTable="setNullSearch"
            />
            <router-view></router-view>
          </b-tab>
        </div>
      </b-tabs>
    </div>

    <div v-else>
      <grid-customer-tickets
        ref="routeView"
        :key="`grid-${gridIndex}`"
        :module="null"
        :code-search="searchCode"
        @refreshTable="setNullSearch"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import GridCustomerTickets from "@/views/commons/components/customer-tickets/views/GridCustomerTickets.vue";
import store from "@/store";

export default {
  components: {
    GridCustomerTickets,
  },
  // data
  data() {
    return {
      tabsKey: 0,
      oldModuleIds: [],
      newModuleIds: [],
      searchByCode: false,
      searchModule: null,
      searchCode: null,
      activeTab: 0,
      gridIndex: 0,
    };
  },
  computed: {
    ...mapGetters({
      G_COUNT_PENDING_CUSTOMER_TICKETS_BY_MODULE:
        "NotificationStore/G_COUNT_PENDING_CUSTOMER_TICKETS_BY_MODULE",
      currentType: "TicketCustomerStore/G_TYPE_TICKET",
    }),
  },
  async mounted() {
    this.addPreloader();
    await this.A_COUNT_PENDING_CUSTOMER_TICKETS_BY_MODULE({
      user_id: 1,
      type_ticket: this.currentType,
    });
    this.removePreloader();
  },
  created() {
    this.changeTabModule();
    this.$root.$on("redirect-to-module", this.handleSameRouteEvent);
  },
  beforeDestroy() {
    // Desregistrar el evento al destruir el componente para evitar fugas de memoria
    this.$root.$off("redirect-to-module", this.handleSameRouteEvent);
  },
  methods: {
    setNullSearch() {
      this.searchCode = "";
    },
    handleTabClick() {
      this.$store.dispatch("TicketCustomerStore/A_SET_CODE", null);
    },
    async changeTabModule() {
      this.searchCode = this.$store.getters["TicketCustomerStore/G_CODE"];

      // Verificar que G_COUNT_PENDING_CUSTOMER_TICKETS_BY_MODULE esté definido y sea un array
      if (
        this.G_COUNT_PENDING_CUSTOMER_TICKETS_BY_MODULE &&
        Array.isArray(this.G_COUNT_PENDING_CUSTOMER_TICKETS_BY_MODULE)
      ) {
        this.G_COUNT_PENDING_CUSTOMER_TICKETS_BY_MODULE.forEach(
          (item, index) => {
            if (item.module_id === this.$route.params.module) {
              this.activeTab = index;
            }
          }
        );
      }
    },
    async handleSameRouteEvent(data) {
      if (data) {
        this.gridIndex += 1;
        this.searchCode = data.code;
        await this.G_COUNT_PENDING_CUSTOMER_TICKETS_BY_MODULE.forEach(
          (item, index) => {
            if (item.module_id === data.module) {
              this.activeTab = index;
            }
          }
        );
      } else {
        this.changeTabModule();
      }
    },
    hasModuleIdOrderChanged() {
      // Verificar si el order de module_id ha cambiado
      return (
        JSON.stringify(this.newModuleIds) !== JSON.stringify(this.oldModuleIds)
      );
    },
    forceGridUpdate() {
      this.gridIndex += 1;
    },
    ...mapActions({
      A_COUNT_PENDING_CUSTOMER_TICKETS_BY_MODULE:
        "NotificationStore/A_COUNT_PENDING_CUSTOMER_TICKETS_BY_MODULE",
    }),
  },
  watch: {
    currentType() {
      this.forceGridUpdate();
    },
    searchCode() {
      this.forceGridUpdate();
    },
  },
};
</script>
<style>
.tab-customer-ticket-module1 .nav-link {
  padding: 0 !important;
}
.counter-tabs-customer-tickets-modules {
  position: relative;
  /* top: 2px !important; */
}
</style>
